import HttpService from './HttpService';
import LocalStorageService from './LocalStorageService';

const AuthenticationService = {
  login: (username, password) => HttpService.login(username, password),

  loginToken: (token) => HttpService.loginToken(token),

  isAccessTokenValid: () => {
    const token = LocalStorageService.getAccessToken();
    return token !== undefined && token !== null;
  },
};

export default AuthenticationService;
