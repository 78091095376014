import { Backdrop, CircularProgress } from "@material-ui/core";
import Axios from "axios";
import React from "react";
import ReactDOM from "react-dom";
import { FormattedMessage, useIntl } from "react-intl";
import { useSnackbar } from "notistack";
// import ResponsiveDialog from "../components/shared/ResponsiveDialog";
import LocalStorageService from "../services/LocalStorageService";

const { lightBlue } = require("@material-ui/core/colors");

const backdrop = (
  <Backdrop open style={{ zIndex: "100000000", color: "#fff" }}>
    <CircularProgress style={{ color: lightBlue[300] }} />
  </Backdrop>
);

const setLoading = (value) => {
  if (value) {
    ReactDOM.render(backdrop, document.getElementById("backdrop"));
  } else {
    ReactDOM.render(<></>, document.getElementById("backdrop"));
  }
};
// const showModal = (value) => {
//   if (value) {
//     ReactDOM.render(modal, document.getElementById("backdrop"));
//   } else {
//     if (window.location.pathname !== "/login") window.location.href = "/login";
//     ReactDOM.render(<></>, document.getElementById("backdrop"));
//   }
// };

// const modal = (
//   <ResponsiveDialog
//     message={"Session Expired - Redirecting to login page"}
//     isOpen={true}
//     setOpen={showModal}
//     PaperProps={{
//       style: {
//         backgroundColor: "#424242",
//         color: "white",
//       },
//     }}
//   />
// );

const axiosInstance = Axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

const createAxiosRequestInterceptor = () => {
  if (
    !sessionStorage.getItem("Auth") &&
    !localStorage.getItem("rememberMeUser")
  ) {
    sessionStorage.setItem("Auth", "false");
    if (!window.location.href.includes("confirmation") &&
        !window.location.href.includes("screen-share-fengine") &&
        !window.location.href.includes("screen-share") &&
        !window.location.href.includes("messaging-fengine") &&
        !window.location.href.includes("message") &&
        !window.location.href.includes("file")) {
      window.location.href = "/logout";
    }
  }
  axiosInstance.interceptors.request.use(
    (config) => {
      if (!config.hideLoading) setLoading(true);
      if (config.url !== "/auth") {
        const token = LocalStorageService.getAccessToken();
        if (token !== undefined && token !== null) {
          config.headers.Authorization = `Bearer ${token}`;
        } else {
          LocalStorageService.clear();
          //window.location.href = "/logout";
        }
      }
      return config;
    },
    (error) => {
      setLoading(false);
      return Promise.reject(error);
    }
  );
};

const createAxiosResponseInterceptor = () => {
  const interceptor = axiosInstance.interceptors.response.use(
    (response) => {
      if (!response.config.hideLoading) {
        if (response.config.url.includes("/extractor/edit")) {
          if (response.data) {
            setLoading(false);
          }
        } else {
          setLoading(false);
        }
      }
      return response;
    },
    (error) => {
      if (!error.response) {
        setLoading(false);
        return Promise.reject(error);
      }

      if (error?.response?.data === "INVALID_CREDENTIALS") {
        setLoading(false);
        return Promise.reject(error);
      }

      if (error.response && error.response.status !== 401) {
        setLoading(false);
        return Promise.reject(error);
      }

      axiosInstance.interceptors.response.eject(interceptor);
      const refreshToken = LocalStorageService.getRefreshToken();
      if (refreshToken && window.location.pathname !== "/login") {
        return fetch(`${process.env.REACT_APP_API_BASE_URL}/auth`, {
          method: "PUT",
          body: JSON.stringify({ refreshToken }),
          headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json;charset=utf-8",
          },
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error(`HTTP error ${response.status}`);
            }

            return response.json();
          }).then((jsonResponse) => {
              LocalStorageService.setAccessToken(jsonResponse.accessToken);
              LocalStorageService.setRefreshToken(jsonResponse.refreshToken);
              LocalStorageService.setUsername(jsonResponse.username);

              error.response.config.headers.Authorization = `Bearer ${jsonResponse.accessToken}`;

              return axiosInstance(error.response.config);
          })
          .catch((errorRefresh) => {
            LocalStorageService.clear();

            if (window.location.pathname !== "/login" && !window.location.href.includes("confirmation")) {
              window.location.href = "/login?errorToken=1";
            }

            return Promise.reject(errorRefresh);
          })
          .finally(() => {
            setLoading(false);
            createAxiosResponseInterceptor();
          });
      }
      createAxiosResponseInterceptor();
      setLoading(false);
      return Promise.reject(error);
    }
  );
};

createAxiosRequestInterceptor();
createAxiosResponseInterceptor();

export default axiosInstance;
