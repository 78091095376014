import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import PropTypes from "prop-types";

function ResponsiveDialog(props) {
  const { message, isOpen, setOpen, title, handleCancel, showCancel, hideClose } = props;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {title === "" ? "Error" : title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          {!hideClose && (
              <Button
                onClick={handleClose}
                color="primary"
                style={{ background: "#4FC3F7" }}
              >
                Ok
              </Button>
            )}
          {showCancel && (
            <Button
              onClick={handleCancel}
              color="primary"
              style={{ background: "#4FC3F7" }}
            >
              Cancel
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}

ResponsiveDialog.propTypes = {
  message: PropTypes.string,
  isOpen: PropTypes.bool,
  setOpen: PropTypes.func,
  title: PropTypes.string,
  showCancel: PropTypes.bool,
  handleCancel: PropTypes.func,
};

ResponsiveDialog.defaultProps = {
  title: "",
  message: "",
  isOpen: false,
  showCancel: false,
  setOpen: () => {},
  handleCancel: () => {},
};

export default ResponsiveDialog;
