const ACCESS_TOKEN_ATTR_NAME = 'accessToken';
const REFRESH_TOKEN_ATTR_NAME = 'refreshToken';
const USERNAME_ATTR_NAME = 'username';
const REMEMBER_ME_USER_SESSION_ATTR_NAME = 'rememberMeUser';
const NAVIGATOR_TOKEN = 'navigatorToken';
const FENGINE = 'fengine';
const MESSAGING = 'messaging';
const TEMP_PASSWORD_SET = 'tempPasswordSet';
const USERID_ATTR_NAME = 'id';

const LocalStorageService = {
  setAccessToken: (token) => { localStorage.setItem(ACCESS_TOKEN_ATTR_NAME, token); },

  getAccessToken: () => localStorage.getItem(ACCESS_TOKEN_ATTR_NAME),

  setRefreshToken: (token) => { localStorage.setItem(REFRESH_TOKEN_ATTR_NAME, token); },

  getRefreshToken: () => localStorage.getItem(REFRESH_TOKEN_ATTR_NAME),

  setUsername: (username) => { localStorage.setItem(USERNAME_ATTR_NAME, username); },

  getUsername: () => localStorage.getItem(USERNAME_ATTR_NAME),

  setUserId: (id) => { localStorage.setItem(USERID_ATTR_NAME, id); },

  getUserId: () => localStorage.getItem(USERID_ATTR_NAME),

  setNavigatorToken: (token) => { localStorage.setItem(NAVIGATOR_TOKEN, token); },

  getNavigatorToken: () => localStorage.getItem(NAVIGATOR_TOKEN),

  removeNavigatorToken: () => localStorage.removeItem(NAVIGATOR_TOKEN),

  setFEngine: (state) => { localStorage.setItem(FENGINE, state); },

  getFEngine: () => localStorage.getItem(FENGINE),

  removeFEngine: () => localStorage.removeItem(FENGINE),

  setMessaging: (state) => { localStorage.setItem(MESSAGING, state); },

  getMessaging: () => localStorage.getItem(MESSAGING),

  removeMessaging: () => localStorage.removeItem(MESSAGING),

  setTempPasswordSet: (value) => { localStorage.setItem(TEMP_PASSWORD_SET, value); },

  getTempPasswordSet: () => localStorage.getItem(TEMP_PASSWORD_SET),

  clear: () => {
    localStorage.removeItem(ACCESS_TOKEN_ATTR_NAME);
    localStorage.removeItem(REFRESH_TOKEN_ATTR_NAME);
    localStorage.removeItem(USERNAME_ATTR_NAME);
    localStorage.removeItem(FENGINE);
    localStorage.removeItem(MESSAGING);
    localStorage.removeItem(TEMP_PASSWORD_SET);
    localStorage.removeItem(USERID_ATTR_NAME);
  },

  setRememberMeUser: (user) => { localStorage.setItem(REMEMBER_ME_USER_SESSION_ATTR_NAME, user); },

  getRememberMeUser: () => localStorage.getItem(REMEMBER_ME_USER_SESSION_ATTR_NAME),

  removeRememberMeUser: () => { localStorage.removeItem(REMEMBER_ME_USER_SESSION_ATTR_NAME); },
};

export default LocalStorageService;
