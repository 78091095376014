import HttpService from "./HttpService";

const MessageService = {
  getMessagesByToken: (token) => HttpService.getMessagesByToken(token),

  getMessagesById: (id) => HttpService.getMessagesById(id),

  getMessagesByUserId: (id) => HttpService.getMessagesByUserId(id),

  getMessagesByReplyOf: (id) => HttpService.getMessagesByReplyOf(id),

  getSentMessages: () => HttpService.getSentMessages(),

  getReceivedMessages: () => HttpService.getReceivedMessages(),

  deleteMessagesById: (id) => HttpService.deleteMessagesById(id),

  sendMessage: (message) => HttpService.sendMessage(message),

  reply: (message) => HttpService.reply(message),

  getFileById: (id) => HttpService.getFileById(id)
};

export default MessageService;
