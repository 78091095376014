import {
  Box,
  Divider,
  Grid,
  IconButton,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import ReplayOutlinedIcon from "@material-ui/icons/ReplayOutlined";
import SaveOutlinedIcon from "@material-ui/icons/SaveOutlined";
import { makeStyles } from "@material-ui/styles";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";
import React, { useContext, useCallback, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import ExtractorActionStatus from "../../models/enums/ExtractorActionStatus";
import ExtractorActionType from "../../models/enums/ExtractorActionType";
import ExtractorActionValidation from "../../models/enums/ExtractorActionValidation";
import ExtractorStatus from "../../models/enums/ExtractorStatus";
import ExtractorAction from "../../models/ExtractorAction";
import ExtractorActionGetTable from "../../models/ExtractorActionGetTable";
import ExtractorActionGetTableColumn from "../../models/ExtractorActionGetTableColumn";
import ExtractorService from "../../services/ExtractorService";
import GenericModal from "../shared/GenericModal";
import ListActions from "../shared/extractor/actions/ListActions";
import SelectAction from "../shared/extractor/actions/SelectAction";
import FieldSelect from "../shared/extractor/actions/FieldSelect";
import InvestorSelect from "../shared/extractor/actions/InvestorSelect";
import ResponsiveDialog from "../shared/ResponsiveDialog";
import Normalized from "../shared/extractor/normalized/Normalized";
import ExtractorActionAskType from "../../models/enums/ExtractorActionAskType";
import AppContext from "../../context/AppContext";
import LocalStorageService from "../../services/LocalStorageService";

const useStyles = makeStyles((theme) => ({
  container: {
    overflow: "hidden",
    margin: 0,
  },
  grid: {
    margin: 0,
    width: "100%",
  },
  extractorName: {
    marginLeft: 18,
  },
  actions: {
    maxHeight: "77vh",
    overflowY: "auto",
  },
  leftMenu: {
    maxHeight: "77vh",
  },
  normalized: {
    height: "85.4vh",
    maxHeight: "85.4vh",
    overflowY: "scroll",
    userSelect: "none",
  },
  iconButton: {
    "&:hover": {
      color: theme.palette.secondary.main,
    },
  },
}));

function Navigator(props) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const intl = useIntl();
  const history = useHistory();
  const [actions, setActions] = useState([]);
  const [normalizedData, setNormalizedData] = useState({});
  const [currentAction, setCurrentAction] = useState(new ExtractorAction());
  const [canAddAction, setCanAddAction] = useState(false);
  const [status, setStatus] = useState(ExtractorStatus.NONE);
  const [showModalConfirmFinish, setShowModalConfirmFinish] = useState(false);
  const { location } = props;
  const { extractorType } = props;
  const { extractorProps } = location;
  const [editInput, setEditInput] = useState();
  const [showModalEditAction, setShowModalEditAction] = useState(false);
  const [isAdaptable, setIsAdaptable] = useState(true);
  const [image, setImage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [investors, setInvestors] = useState([]);
  const [showInvestor, setShowInvestor] = useState(false);
  const [typeFieldValue, setTypeFieldValue] = useState("");
  const [sections, setSections] = useState([]);
  const [showFields, setShowFields] = useState(false);
  const [state] = useContext(AppContext);
  const [closed, setClosed] = useState(false);

  const handleMessageNotification = useCallback(
    (message, errorProps) => {
      if (errorProps.variant === "error") {
        setErrorMessage(message);
        setShowErrorMessage(true);
      } else {
        enqueueSnackbar(message, errorProps);
      }
    },
    [errorMessage]
  );

  const getUpdatedActions = (arr, sequence, modifySequenceAhead) =>
    arr.map((a) => {
      if (a.sequence === sequence) {
        a.validation = ExtractorActionValidation.NOTVALIDATED;
        a.elementScreenshot = undefined;
      }
      // else if (modifySequenceAhead && a.sequence > sequence) {
      //   a.validation = ExtractorActionValidation.NOTVALIDATED;
      // }
      a.status = ExtractorActionStatus.NONE;
      return a;
    });

  const changeAction = (action) => {
    setIsAdaptable(true);
    ExtractorService.changeAction(action).then(
      (res) => {
        if (res.data.options.text) {
          setEditInput(res.data.options.text);
        }

        actions.map((a) => {
          if (a.sequence === res.data.sequence) {
            a.selectorValue = res.data.selectorValue;
            a.options = res.data.options;
            a.label = res.data.label;
          }
          return a;
        });

        const newActions = getUpdatedActions(
          actions,
          currentAction.sequence,
          true
        );
        setActions(newActions);
        setNormalizedData({});
        setCanAddAction(false);

        handleMessageNotification(
          intl.formatMessage({ id: "navigator.change.action.success" }),
          { variant: "success" }
        );
      },
      () => {
        handleMessageNotification(
          intl.formatMessage({ id: "navigator.change.action.error" }),
          { variant: "error" }
        );
      }
    );
  };

  const reloadExtractor = useCallback(() => {
    ExtractorService.reload().then(
      (response) => {
        if (response.data.pageScreenshot) {
          setImage(response.data.pageScreenshot);
        }
        setNormalizedData(response.data);
        setSections(response.data?.fserviceDTO?.sections);
        setIsAdaptable(true);
      },
      () => {
        handleMessageNotification(
          intl.formatMessage({ id: "navigator.reload.error" }),
          { variant: "error" }
        );
      }
    );
  }, [intl]);

  const checkImage = async () => {
    if (actions.length > 0 && actions[0].id == null && state.readOnlyEdit) {
      ExtractorService.GetLastImage().then((res) => {
        if (res.data) {
          setImage(res.data);
        }
      },
      () => {
        enqueueSnackbar(intl.formatMessage({ id: "error.while.loading.browser.screenshot" }), {
          variant: "error",
        });
      });
    }
  };

  useEffect(() => {
    const func = setInterval(checkImage, state.checkImageRefreshInterval);
    return () => clearInterval(func);
  }, []);

  const executeActionAndGetCurrentNormalized = (action) => {
    ExtractorService.executeAction(action).then(
      (res) => {
        if (res.data.pageScreenshot) {
          setImage(res.data.pageScreenshot);
        }

        if (action.type === ExtractorActionType.ASK_RECAPTCHA) {
          ExtractorService.getNormalizedData(currentAction).then(
            (result) => {
              if (res.data === "DONE") {
                action.validation = ExtractorAction.SUCCESS;
                setStatus(ExtractorStatus.NONE);
                const actionsArray = actions.map((a) =>
                  a.sequence !== action.sequence ? a : action
                );
                setActions(actionsArray);
              } else {
                action.validation = ExtractorAction.SUCCESS;
                setStatus(ExtractorStatus.NONE);
                const actionsArray = actions.map((a) =>
                  a.sequence !== action.sequence ? a : action
                );
                setActions(actionsArray);
              }
              setNormalizedData(result.data);
              setCurrentAction(currentAction);
            },
            () => {
              handleMessageNotification(
                intl.formatMessage({ id: "navigator.normalized.error" }),
                { variant: "error" }
              );
            }
          );
        } else {
          if (action.type === ExtractorActionType.SCRIPT) {
            action.label = res.data.label;
            const actionsArray = actions.map((a) =>
              a.sequence !== action.sequence ? a : action
            );
            setActions(actionsArray);
          }
          if (
            action.type !== ExtractorActionType.NAVIGATE &&
            action.type !== ExtractorActionType.GET_TABLE &&
            action.type !== ExtractorActionType.SCROLL_UP &&
            action.type !== ExtractorActionType.SCROLL_DOWN &&
            action.type !== ExtractorActionType.SCROLL_TOP &&
            action.type !== ExtractorActionType.SCROLL_BOTTOM &&
            action.type !== ExtractorActionType.GO_BACK &&
            action.type !== ExtractorActionType.GO_FORWARD &&
            action.type !== ExtractorActionType.WAIT &&
            action.type !== ExtractorActionType.END &&
            action.type !== ExtractorActionType.IF_CONDITION &&
            action.type !== ExtractorActionType.SCRIPT &&
            action.type !== ExtractorActionType.RAISE_ERROR &&
            action.type !== ExtractorActionType.ASK_F_ENGINE_PASSWORD &&
            action.type !== ExtractorActionType.ASK_F_ENGINE_INVESTOR &&
            action.type !== ExtractorActionType.ASK_F_ENGINE_USER &&
            action.type !== ExtractorActionType.ASK_F_ENGINE_URL &&
            action.type !== ExtractorActionType.ASK_F_ENGINE_FIELDS
          ) {
            ExtractorService.getElementScreenshot(action.sequence).then(
              (elementScreenshotResponse) => {
                action.elementScreenshot = elementScreenshotResponse.data;
                if (actions.find((a) => a.sequence === action.sequence)) {
                  const actionsArray = actions.map((a) =>
                    a.sequence !== action.sequence ? a : action
                  );
                  setActions(actionsArray);
                } else {
                  setActions((prevState) => prevState.concat(action));
                }
              },
              () => {
                handleMessageNotification(
                  intl.formatMessage({ id: "navigator.screenshot.error" }),
                  { variant: "error" }
                );
              }
            );
          } else if (
            action.type === ExtractorActionType.ASK_F_ENGINE_INVESTOR
          ) {
            if (res.data?.fserviceDTO?.sections) {
              setSections(res.data?.fserviceDTO?.sections);
              action.managementDTO = res.data.managementDTO;
              setActions((prevState) => prevState.concat(action));
              setShowInvestor(false);
              //setShowFields(true);
            } else if (res.data?.managementDTO?.investors?.length !== 0) {
              if (!res.data.options.select.option) {
                setInvestors(res.data.managementDTO.investors);

                //setStatus(ExtractorStatus.NONE);
                setShowInvestor(true);
              } else {
                setShowInvestor(false);
              }
            } else {
              setShowInvestor(false);
              setStatus(ExtractorStatus.NONE);
              handleMessageNotification(
                `${intl.formatMessage({
                  id: "navigator.execute.error",
                })}: ${"You must sign in to use this action"}`,
                { variant: "error" }
              );
            }
          } else if (action.type === ExtractorActionType.Ask_F_Engine_Fields) {
            const test = 1;
          } else {
            setActions((prevState) => prevState.concat(action));
          }
        }
      },
      (err) => {
        let msg;
        if (err.response.data && err.response.data.error)
          msg = err.response.data.error;
        action.validation = ExtractorActionValidation.ERROR;
        handleMessageNotification(
          `${intl.formatMessage({ id: "navigator.execute.error" })}: ${msg}`,
          { variant: "error" }
        );
      }
    );
    setIsAdaptable(true);
  };

  useEffect(() => {
    if (extractorProps && extractorProps.actions) {
      // EDIT
      setCanAddAction(false);
      setStatus(ExtractorStatus.NONE);
      extractorProps.actions.forEach((a) => {
        a.status = ExtractorActionStatus.NONE;
        a.validation = a.validationFlag
          ? ExtractorActionValidation.SUCCESS
          : ExtractorActionValidation.NOTVALIDATED;
        return a;
      });
      setActions(extractorProps.actions);
    } else if (
      extractorProps &&
      extractorProps.description &&
      extractorProps.name
    ) {
      // ADD NEW
      setCanAddAction(true);
      setStatus(ExtractorStatus.NONE);
    } else {
      // INVALID
      history.push("/");
    }
  }, [handleMessageNotification, extractorProps, history]);

  const finishExtractor = () => {
    ExtractorService.finish().then(
      () => {
        const fengine = LocalStorageService.getFEngine() === 'true';
        if (fengine) {
            setClosed(true);
        } else {
            history.push("/dashboard");
        }
      },
      () => {
        handleMessageNotification(
          intl.formatMessage({ id: "navigator.finish.error" }),
          { variant: "error" }
        );
      }
    );
  };

  const componentConfirmFinish = (
    <Typography>
      <FormattedMessage id="navigator.finishconfirm" />
    </Typography>
  );

  const clickConfirmFinish = () => {
    finishExtractor();
  };

  const closeModalConfirmFinish = () => {
    setShowModalConfirmFinish(false);
  };

  const addAction = (event) => {
    setIsAdaptable(false);
    const newAction = new ExtractorAction(
      parseInt(event.sequence, 10),
      event.selectType
    );
    if (event.selectType === ExtractorActionType.NAVIGATE) {
      setStatus(ExtractorStatus.NONE);
      newAction.options.text = event.inputValue;
      newAction.status = ExtractorActionStatus.NONE;
      executeActionAndGetCurrentNormalized(newAction);
      setCurrentAction(new ExtractorAction());
    } else if (event.selectType === ExtractorActionType.GET_TABLE) {
      setStatus(ExtractorStatus.CREATING);
      newAction.textValue = event.inputValue;
      newAction.status = ExtractorActionStatus.CREATING;
      ExtractorService.getNormalizedData(newAction).then(
        (res) => {
          setNormalizedData(res.data);
        },
        () => {
          handleMessageNotification(
            intl.formatMessage({ id: "navigator.normalized.error" }),
            { variant: "error" }
          );
        }
      );
      setCurrentAction(newAction);
    } else if (
      event.selectType === ExtractorActionType.SCROLL_UP ||
      event.selectType === ExtractorActionType.SCROLL_DOWN ||
      event.selectType === ExtractorActionType.SCROLL_TOP ||
      event.selectType === ExtractorActionType.SCROLL_BOTTOM ||
      event.selectType === ExtractorActionType.GO_BACK ||
      event.selectType === ExtractorActionType.GO_FORWARD ||
      event.selectType === ExtractorActionType.END
    ) {
      setStatus(ExtractorStatus.NONE);
      newAction.status = ExtractorActionStatus.NONE;
      executeActionAndGetCurrentNormalized(newAction);
      setCurrentAction(new ExtractorAction());
    } else if (event.selectType === ExtractorActionType.WAIT) {
      setStatus(ExtractorStatus.NONE);
      newAction.options.number = event.inputValue;
      newAction.status = ExtractorActionStatus.NONE;
      executeActionAndGetCurrentNormalized(newAction);
      setCurrentAction(new ExtractorAction());
    } else if (event.selectType === ExtractorActionType.IF_CONDITION) {
      setStatus(ExtractorStatus.NONE);
      newAction.options.condition.value = event.inputValue;
      newAction.options.condition.sequenceDestination = parseInt(
        event.inputValueNumber,
        10
      );
      newAction.options.condition.sequenceSource = parseInt(
        event.actionSelect.sequence,
        10
      );
      newAction.options.condition.condition = event.selectCondition;
      newAction.status = ExtractorActionStatus.NONE;
      executeActionAndGetCurrentNormalized(newAction);
      setCurrentAction(new ExtractorAction());
    } else if (event.selectType === ExtractorActionType.RAISE_ERROR) {
      setStatus(ExtractorStatus.NONE);
      newAction.options.text = event.inputValue;
      newAction.status = ExtractorActionStatus.NONE;
      executeActionAndGetCurrentNormalized(newAction);
      setCurrentAction(new ExtractorAction());
    } else if (event.selectType === ExtractorActionType.SCRIPT) {
      setStatus(ExtractorStatus.CREATING);
      newAction.status = ExtractorActionStatus.CREATING;
      setCurrentAction(newAction);
    } else if (
      event.selectType === ExtractorActionType.ASK_QUESTION ||
      event.selectType === ExtractorActionType.ASK_CAPTCHA
    ) {
      newAction.options.askMultiStepDto.builderStep =
        ExtractorActionAskType.GET_ASK_DATA;
      setStatus(ExtractorStatus.CREATING);
      newAction.status = ExtractorActionStatus.CREATING;
      setActions((prevState) => prevState.concat(newAction));
      ExtractorService.getNormalizedData(newAction).then(
        (res) => {
          setNormalizedData(res.data);
          setCurrentAction(newAction);
        },
        () => {
          handleMessageNotification(
            intl.formatMessage({ id: "navigator.normalized.error" }),
            { variant: "error" }
          );
        }
      );
    } else if (event.selectType === ExtractorActionType.ASK_RECAPTCHA) {
      setStatus(ExtractorStatus.CREATING);
      newAction.status = ExtractorActionStatus.CREATING;
      setActions((prevState) => prevState.concat(newAction));
      ExtractorService.getNormalizedData(newAction).then(
        (res) => {
          setNormalizedData(res.data);
          setCurrentAction(newAction);
        },
        () => {
          handleMessageNotification(
            intl.formatMessage({ id: "navigator.normalized.error" }),
            { variant: "error" }
          );
        }
      );
    } else if (
      event.selectType === ExtractorActionType.ASK_F_ENGINE_USER ||
      event.selectType === ExtractorActionType.ASK_F_ENGINE_PASSWORD
    ) {
      setStatus(ExtractorStatus.NONE);
      // setStatus(
      //   event.selectType === ExtractorActionType.ASK_F_ENGINE_USER
      //     ? ExtractorActionStatus.ASK_F_ENGINE_USER
      //     : ExtractorActionStatus.ASK_F_ENGINE_PASSWORD
      // );
      newAction.status =
        event.selectType === ExtractorActionType.ASK_F_ENGINE_USER
          ? ExtractorActionStatus.ASK_F_ENGINE_USER
          : ExtractorActionStatus.ASK_F_ENGINE_PASSWORD;
      executeActionAndGetCurrentNormalized(
        new ExtractorAction(
          parseInt(event.sequence, 10),
          event.selectType,
          event.credentials
        )
      );
      setCurrentAction(
        new ExtractorAction(
          parseInt(event.sequence, 10),
          event.selectType,
          event.credentials
        )
      );
    } else if (event.selectType === ExtractorActionType.ASK_F_ENGINE_URL) {
      setStatus(ExtractorStatus.NONE);
      newAction.status =
        event.selectType === ExtractorActionType.ASK_F_ENGINE_URL;
      executeActionAndGetCurrentNormalized(
        new ExtractorAction(
          parseInt(event.sequence, 10),
          event.selectType,
          event.credentials
        )
      );
      setCurrentAction(
        new ExtractorAction(
          parseInt(event.sequence, 10),
          event.selectType,
          event.credentials
        )
      );
    } else if (event.selectType === ExtractorActionType.ASK_F_ENGINE_INVESTOR) {
      setStatus(ExtractorStatus.NONE);
      executeActionAndGetCurrentNormalized(
        new ExtractorAction(
          parseInt(
            event.sequence ? event.sequence : currentAction.sequence,
            10
          ),
          event.selectType,
          undefined,
          event.option
        )
      );

      setCurrentAction(
        new ExtractorAction(
          parseInt(
            event.sequence ? event.sequence : currentAction.sequence,
            10
          ),
          event.selectType
          //event.option
        )
      );
    } else if (event.selectType === ExtractorActionType.TYPE_F_ENGINE_FIELD) {
      if (!showFields) {
        setShowFields(true);
      }

      if (event.option) {
        setTypeFieldValue(event.option);
        setCurrentAction(
          new ExtractorAction(
            parseInt(
              event.sequence ? event.sequence : currentAction.sequence,
              10
            ),
            event.selectType,
            undefined,
            event.option
          )
        );
        ExtractorService.getNormalizedData(newAction).then(
          (res) => {
            setNormalizedData(res.data);
            setShowFields(false);
            setCurrentAction(newAction);
          },
          () => {
            setShowFields(false);
            handleMessageNotification(
              intl.formatMessage({ id: "navigator.normalized.error" }),
              { variant: "error" }
            );
          }
        );
      }

      // executeActionAndGetCurrentNormalized(
      //   new ExtractorAction(
      //     parseInt(
      //       event.sequence ? event.sequence : currentAction.sequence,
      //       10
      //     ),
      //     ExtractorActionType.TYPE_F_ENGINE_FIELD,
      //     null,
      //     event.option
      //   )
      // );
    } else {
      setStatus(ExtractorStatus.CREATING);
      newAction.status = ExtractorActionStatus.CREATING;
      setActions((prevState) => prevState.concat(newAction));
      ExtractorService.getNormalizedData(newAction).then(
        (res) => {
          setNormalizedData(res.data);
          setCurrentAction(newAction);
          if (res.data.pageScreenshot) {
            setImage(res.data.pageScreenshot);
          }
        },
        () => {
          handleMessageNotification(
            intl.formatMessage({ id: "navigator.normalized.error" }),
            { variant: "error" }
          );
        }
      );
      //setCurrentAction(newAction);
    }
  };

  const addActionAndExecute = (event) => {
    const newAction = new ExtractorAction(
      parseInt(event.sequence, 10),
      event.selectType
    );
    setStatus(ExtractorStatus.CREATING);
    newAction.status = ExtractorActionStatus.CREATING;
    if (!event.options.point) {
      ExtractorService.getNormalizedData(newAction).then(
        (res) => {
          setNormalizedData(res.data);
          newAction.status = ExtractorActionStatus.NONE;
          newAction.validation = ExtractorActionValidation.SUCCESS;
          newAction.saveOptionsFlag = false;
          newAction.options = event.options;
          newAction.label = event.label;
          newAction.selectorValue = event.selectorValue;
          setCurrentAction(newAction);
          executeActionAndGetCurrentNormalized(newAction);
        },
        () => {
          handleMessageNotification(
            intl.formatMessage({ id: "navigator.normalized.error" }),
            { variant: "error" }
          );
        }
      );
    } else {
      newAction.status = ExtractorActionStatus.NONE;
      newAction.validation = ExtractorActionValidation.SUCCESS;
      newAction.saveOptionsFlag = false;
      newAction.options = event.options;
      newAction.label = event.label;
      newAction.selectorValue = event.selectorValue;
      setCurrentAction(newAction);
      executeActionAndGetCurrentNormalized(newAction);
    }
  };

  const getActionByType = (types) =>
    actions.filter((a) => types.includes(a.type));

  const removeAction = (sequence) => {
    if (actions[0].sequence !== sequence) {
      ExtractorService.deleteAction(sequence).then(
        () => {
          let newAction = getUpdatedActions(actions, sequence, true);
          newAction = newAction.filter((data) => data.sequence !== sequence);
          setActions(newAction);
          setNormalizedData({});
          setCanAddAction(false);
          handleMessageNotification(
            intl.formatMessage({ id: "navigator.must.validate" }),
            { variant: "warning" }
          );
        },
        () => {
          handleMessageNotification(
            intl.formatMessage({ id: "navigator.execute.error" }),
            { variant: "error" }
          );
        }
      );
    } else {
      handleMessageNotification(
        intl.formatMessage({ id: "navigator.first.action.delete.warning" }),
        { variant: "warning" }
      );
    }
  };

  const cancelAction = (cancelledAction) => {
    const sequence = cancelledAction
      ? cancelledAction.sequence
      : currentAction.sequence;
    const validation = cancelledAction
      ? cancelledAction.validation
      : currentAction.validation;
    setIsAdaptable(true);
    if (canAddAction) {
      let newActions;
      if (status === ExtractorActionStatus.CREATING)
        newActions = actions.filter((a) => a.sequence !== sequence);
      else newActions = actions;

      newActions = newActions.map((a) => {
        a.status = ExtractorActionStatus.NONE;
        return a;
      });

      setActions(newActions);
    } else {
      setActions((prevState) =>
        prevState.map((a) => {
          if (a.sequence === sequence) {
            a.status = ExtractorActionStatus.NONE;
            a.validation = validation;
          }
          return a;
        })
      );
    }
    setNormalizedData({});
    setStatus(ExtractorStatus.NONE);
    setCurrentAction(new ExtractorAction());

    if (canAddAction) reloadExtractor();
  };

  const editAction = (action) => {
    setIsAdaptable(false);
    if (action.type === ExtractorActionType.NAVIGATE) {
      ExtractorService.getNormalizedToEdit(action.sequence).then(
        (res) => {
          setCurrentAction(action);
          setNormalizedData(res.data);
          setShowModalEditAction(true);

          setEditInput(action.options.text);
        },
        () => {
          handleMessageNotification(
            intl.formatMessage({ id: "navigator.normalized.error" }),
            { variant: "error" }
          );
        }
      );
      setActions((prevState) =>
        prevState.map((a) => {
          if (a.sequence === action.sequence)
            a.status = ExtractorActionStatus.EDITING;
          return a;
        })
      );
    } else if (
      action.type === ExtractorActionType.WAIT ||
      action.type === ExtractorActionType.RAISE_ERROR
    ) {
      ExtractorService.getNormalizedToEdit(action.sequence).then(
        (res) => {
          setCurrentAction(action);
          setNormalizedData(res.data);
          setShowModalEditAction(true);
          setEditInput(action.textValue);
        },
        () => {
          handleMessageNotification(
            intl.formatMessage({ id: "navigator.normalized.error" }),
            { variant: "error" }
          );
        }
      );
      setActions((prevState) =>
        prevState.map((a) => {
          if (a.sequence === action.sequence)
            a.status = ExtractorActionStatus.EDITING;
          return a;
        })
      );
    } else if (
      action.type === ExtractorActionType.SCROLL_UP ||
      action.type === ExtractorActionType.SCROLL_DOWN ||
      action.type === ExtractorActionType.SCROLL_TOP ||
      action.type === ExtractorActionType.SCROLL_BOTTOM ||
      action.type === ExtractorActionType.GO_BACK ||
      action.type === ExtractorActionType.GO_FORWARD ||
      action.type === ExtractorActionType.END
    ) {
      handleMessageNotification(
        intl.formatMessage({ id: "navigator.edition.info" }),
        { variant: "info" }
      );
    } else {
      setStatus(ExtractorStatus.EDITING);
      ExtractorService.getNormalizedData(action).then(
        (res) => {
          action.status = ExtractorActionStatus.EDITING;
          setCurrentAction(action);
          setNormalizedData(res.data);
        },
        () => {
          action.status = ExtractorActionStatus.NONE;
          action.validation = ExtractorActionValidation.ERROR;

          handleMessageNotification(
            intl.formatMessage({ id: "navigator.normalized.error" }),
            { variant: "error" }
          );
        }
      );
      setActions((prevState) =>
        prevState.map((a) => {
          if (a.sequence === action.sequence) {
            a.status = action.status;
            a.validation = action.validation;
          }
          return a;
        })
      );
    }
  };

  const onChangeEditInput = (event) => {
    setEditInput(event.target.value);
  };

  const getinputStateLabel = () => {
    if (currentAction.type === ExtractorActionType.NAVIGATE) {
      return "URL";
    }
    if (currentAction.type === ExtractorActionType.GET_TABLE) {
      return "Table Name";
    }
    if (currentAction.type === ExtractorActionType.WAIT) {
      return "Time (max 60s)";
    }
    if (currentAction.type === ExtractorActionType.SCRIPT) {
      return "Script";
    }
    if (currentAction.type === ExtractorActionType.RAISE_ERROR) {
      return "Message";
    }
    return "";
  };

  const componentEditAction = () => (
    <Grid container spacing={2}>
      <Grid item sm={12}>
        <TextField
          label={getinputStateLabel()}
          fullWidth
          required
          value={editInput || ""}
          onChange={onChangeEditInput}
        />
      </Grid>
    </Grid>
  );

  const editSequence = (event) => {
    if (event.action.sequence === actions[0].sequence) {
      handleMessageNotification(
        intl.formatMessage({ id: "navigator.first.action.change.warning" }),
        { variant: "warning" }
      );
    } else if (event.editInputSequence > actions[0].sequence) {
      ExtractorService.changeSequence(
        event.action.sequence,
        parseInt(event.editInputSequence, 10)
      ).then(
        () => {
          let newActions = getUpdatedActions(
            actions,
            event.action.sequence,
            true
          );
          newActions = newActions.map((data) => {
            if (data.sequence === event.action.sequence) {
              data.sequence = parseInt(event.editInputSequence, 10);
            }
            return data;
          });
          setActions(newActions);

          handleMessageNotification(
            intl.formatMessage({ id: "navigator.sequence.change.success" }),
            { variant: "success" }
          );
        },
        () => {
          handleMessageNotification(
            intl.formatMessage({ id: "navigator.sequence.change.error" }),
            { variant: "error" }
          );
        }
      );
    } else {
      handleMessageNotification(
        intl.formatMessage({ id: "navigator.sequence.change.warning" }),
        { variant: "warning" }
      );
    }
  };

  const resequence = () => {
    ExtractorService.resequenceActions().then(
      (response) => {
        const newAction = actions.map((data) => {
          const newSequence = response.data.find(
            (element) => element.sequenceBefore === data.sequence
          );
          data.sequence = newSequence.sequenceAfter;
          return data;
        });
        newAction.sort(
          (a, b) => parseFloat(a.sequence) - parseFloat(b.sequence)
        );
        setActions(newAction);
      },
      () => {
        handleMessageNotification(
          intl.formatMessage({ id: "navigator.resequence.error" }),
          { variant: "error" }
        );
      }
    );
  };

  const onFinishAction = (data) => {
    if (
      currentAction.options.askMultiStepDto !== undefined &&
      currentAction.options.askMultiStepDto.builderStep ===
        ExtractorActionAskType.GET_ASK_DATA
    ) {
      currentAction.options.askMultiStepDto.askDataContentSelector =
        data.selectorValue;
      currentAction.options.askMultiStepDto.builderStep =
        ExtractorActionAskType.USER_RESPONSE;
      setStatus(ExtractorStatus.CREATING);
      currentAction.status = ExtractorActionStatus.CREATING;
      ExtractorService.getNormalizedData(currentAction).then(
        (res) => {
          setNormalizedData(res.data);
          setCurrentAction(currentAction);
        },
        () => {
          handleMessageNotification(
            intl.formatMessage({ id: "navigator.normalized.error" }),
            { variant: "error" }
          );
        }
      );
    } else if (
      currentAction.type === ExtractorActionType.ASK_RECAPTCHA &&
      currentAction.selectorValue == null
    ) {
      currentAction.selectorValue = data.selectorValue;
      setStatus(ExtractorStatus.CREATING);
      currentAction.status = ExtractorActionStatus.CREATING;
      ExtractorService.getNormalizedData(currentAction).then(
        (res) => {
          setNormalizedData(res.data);
          setCurrentAction(currentAction);
        },
        () => {
          handleMessageNotification(
            intl.formatMessage({ id: "navigator.normalized.error" }),
            { variant: "error" }
          );
        }
      );
    } else {
      let newAction = new ExtractorAction(
        currentAction.sequence,
        currentAction
      );
      newAction.label = data.label;
      newAction.options = data.options;
      newAction.sequence = parseInt(currentAction.sequence, 10);
      newAction.type = currentAction.type;
      newAction.selectorValue = data.selectorValue;
      if (
        currentAction.type === ExtractorActionType.GET_DATE ||
        currentAction.type === ExtractorActionType.GET_NUMBER
      ) {
        newAction = {
          ...currentAction,
          selectorValue: data.selectorValue,
          options: data.options,
          label: data.label,
        };
      } else if (currentAction.type === ExtractorActionType.GET_TABLE) {
        newAction.selectorValue = data.tableSelector;
        const tableName = currentAction.textValue
          ? currentAction.textValue
          : currentAction.extractorActionGetTableDto.name;
        newAction.extractorActionGetTableDto = new ExtractorActionGetTable(
          tableName,
          data.rowSelector,
          data.columns.map(
            (c) =>
              new ExtractorActionGetTableColumn(
                c.label,
                c.selector,
                c.type,
                c.options
              )
          )
        );
      } else if (
        currentAction.options.askMultiStepDto !== undefined &&
        currentAction.options.askMultiStepDto.builderStep ===
          ExtractorActionAskType.USER_RESPONSE
      ) {
        newAction.label = data.label;
        newAction.options = data.options;
        newAction = {
          ...currentAction,
          label: data.label,
        };
        newAction.selectorValue = data.selectorValue;
        newAction.options.text = data.options.text;
      } else if (
        currentAction.options.askMultiStepDto !== undefined &&
        currentAction.options.askMultiStepDto.builderStep ===
          ExtractorActionAskType.USER_RESPONSE
      ) {
        if (newAction.options.askMultiStepDto === undefined) {
          newAction.options.askMultiStepDto = {};
        }
        newAction.options.askMultiStepDto.askDataContentSelector =
          data.selectorValue;
      } else if (
        currentAction.type === ExtractorActionType.ASK_RECAPTCHA &&
        currentAction.selectorValue != null
      ) {
        if (newAction.options.askMultiStepDto === undefined) {
          newAction.options.askMultiStepDto = {};
        }
        newAction.selectorValue = currentAction.selectorValue;
        newAction.options.askMultiStepDto.askDataContentSelector =
          data.selectorValue;
      } else if (
        data.adaptiveAction === true &&
        currentAction.status !== ExtractorActionStatus.EDITING
      ) {
        newAction = data;
        newAction.sequence = parseInt(
          actions[actions.length - 1].sequence + 10,
          10
        );
        newAction.selectType = data.type;
        addActionAndExecute(newAction);
      } else if (
        currentAction.selectorValue !== undefined &&
        currentAction.status !== ExtractorActionStatus.EDITING
      ) {
        newAction.selectorValue = currentAction.selectorValue;
      } else {
        newAction.selectorValue = data.selectorValue;
      }
      if (
        (status === ExtractorStatus.CREATING ||
          status === ExtractorStatus.NONE) &&
        data.adaptiveAction !== true
      ) {
        newAction.status = ExtractorActionStatus.NONE;
        newAction.saveOptionsFlag = false;
        executeActionAndGetCurrentNormalized(newAction);
      } else if (
        status === ExtractorStatus.EDITING ||
        currentAction.status === ExtractorActionStatus.EDITING
      ) {
        // see handleFinishEditAction() also
        changeAction(newAction);
      }
      if (currentAction.type !== ExtractorActionType.ASK_RECAPTCHA) {
        setNormalizedData({});
        setStatus(ExtractorStatus.NONE);
        setCurrentAction(new ExtractorAction());
      }
    }
  };

  const handleCloseEditAction = () => {
    setShowModalEditAction(false);
    setCurrentAction(new ExtractorAction());
    setNormalizedData({});
    setActions((prevState) =>
      prevState.map((a) => {
        a.status = ExtractorActionStatus.NONE;
        return a;
      })
    );
    setStatus(ExtractorStatus.NONE);
  };

  const handleFinishEditAction = () => {
    if (
      currentAction.type === ExtractorActionType.TYPE_TEXT ||
      currentAction.type === ExtractorActionType.NAVIGATE ||
      currentAction.type === ExtractorActionType.ASK_ID ||
      currentAction.type === ExtractorActionType.ASK_CODE ||
      currentAction.type === ExtractorActionType.SCRIPT ||
      currentAction.type === ExtractorActionType.TYPE_PASSWORD
    ) {
      currentAction.options.text = editInput;
    } else if (currentAction.type === ExtractorActionType.ASK_PASSWORD) {
      currentAction.options.text = editInput;
      currentAction.saveOptionsFlag = false;
    } else if (currentAction.type === ExtractorActionType.WAIT) {
      currentAction.options.number = editInput;
    } else if (currentAction.type === ExtractorActionType.RAISE_ERROR) {
      currentAction.options.text = editInput;
    } else if (currentAction.type === ExtractorActionType.CLICK_SELECT) {
      currentAction.options.option = editInput;
    }
    changeAction(currentAction);
    handleCloseEditAction();
  };

  const validate = () => {
    setCurrentAction(new ExtractorAction());
    setNormalizedData({});
    if (status === ExtractorStatus.EDITING) {
      setStatus(ExtractorStatus.VALIDATING_EDITING);
    } else {
      setStatus(ExtractorStatus.VALIDATING);
    }
    setIsAdaptable(true);
  };
  const changeIsAdaptable = () => {
    setIsAdaptable(true);
  };

  const validateCallback = useCallback(
    (event) => {
      if (event.success) {
        handleMessageNotification(
          intl.formatMessage({ id: "navigator.validate.success" }),
          { variant: "success" }
        );
        ExtractorService.getAllElementScreenshots()
          .then((response) => {
            const screenshotsMap = response.data;
            setActions((prevState) =>
              prevState.map((a) => {
                a.status = ExtractorActionStatus.NONE;
                a.validation = ExtractorActionValidation.SUCCESS;
                a.elementScreenshot = screenshotsMap[a.sequence];
                return a;
              })
            );
          })
          .catch(() => {});
        reloadExtractor();
        setCanAddAction(true);
        setStatus(ExtractorStatus.VALIDATING);
      } else {
        setActions((prevState) =>
          prevState.map((a) => {
            a.status = ExtractorActionStatus.NONE;
            if (a.sequence < event.errorSequence) {
              a.validation = ExtractorActionValidation.SUCCESS;
            } else if (a.sequence === event.errorSequence) {
              a.validation = ExtractorActionValidation.ERROR;
              a.errorMessage = event.errorMessage;
            } else {
              a.validation = ExtractorActionValidation.NOTVALIDATED;
            }
            return a;
          })
        );
        handleMessageNotification(event.errorMessage, { variant: "error" });
      }
      if (status === ExtractorStatus.VALIDATING_EDITING) {
        setStatus(ExtractorStatus.EDITING);
      } else {
        setStatus(ExtractorStatus.NONE);
      }
    },
    [handleMessageNotification, reloadExtractor, intl]
  );

  const navigatorPage = () => {
    return (<><Grid container spacing={2} direction="row" className={classes.container}>
       <Grid item sm={10}>
         <Typography className={classes.extractorName} variant="h6" noWrap>
           {extractorProps ? extractorProps.name : ""}
           {extractorProps ? " - " : ""}
           <Typography component="span" variant="subtitle1">
             {extractorProps ? extractorProps.description : ""}
           </Typography>
         </Typography>
       </Grid>
       <Grid item sm={2}>
         <Box display="flex" flexDirection="row-reverse">
           <IconButton
             onClick={() => setShowModalConfirmFinish(true)}
             className={classes.iconButton}
           >
             <Tooltip title={intl.formatMessage({ id: "navigator.close" })}>
               <CloseOutlinedIcon />
             </Tooltip>
           </IconButton>
           <IconButton
             onClick={reloadExtractor}
             className={classes.iconButton}
             disabled={!canAddAction || status !== ExtractorStatus.NONE}
           >
             <Tooltip title={intl.formatMessage({ id: "navigator.reload" })}>
               <ReplayOutlinedIcon />
             </Tooltip>
           </IconButton>
         </Box>
       </Grid>

       <Grid item sm={3} className={classes.leftMenu}>
         <Box display="flex" justifyContent="center">
           <SelectAction
             source={'navigator'}
             addAction={addAction}
             getActionByType={getActionByType}
             isNew={actions.length === 0}
             lastSequence={
               actions.length > 0 ? actions[actions.length - 1].sequence : 0
             }
             maxSequence={
               actions.length > 0
                 ? Math.max.apply(
                     null,
                     actions.map((action) => action.sequence)
                   )
                 : 0
             }
             allSequence={
               actions.length > 0
                 ? actions.map((action) => action.sequence)
                 : [0]
             }
             extractorStatus={status}
             canAddAction={canAddAction}
             extractorProps={extractorProps}
           />
         </Box>

         <Divider />

         <Box className={classes.actions}>
           <ListActions
             actions={actions}
             editAction={editAction}
             editSequence={editSequence}
             removeAction={removeAction}
             cancelAction={cancelAction}
             extractorStatus={status}
           />
         </Box>
       </Grid>

       <Grid item sm={9}>
         <Box component={Paper} className={classes.normalized}>
           <Normalized
             normalizedData={normalizedData}
             setImage={setImage}
             image={image}
             currentAction={currentAction}
             setCurrentAction={setCurrentAction}
             onFinishAction={onFinishAction}
             cancelAction={cancelAction}
             sequences={actions.map((action) => action.sequence)}
             status={status}
             isAdaptable={isAdaptable}
             extractorProps={extractorProps}
             valueInput={editInput}
             setValueInput={setEditInput}
             typeFieldValue={typeFieldValue}
           />
         </Box>
       </Grid>
       {showInvestor && (
         <Grid item sm={3} className={classes.leftMenu}>
           <Box display="flex" justifyContent="center">
             <InvestorSelect
               addAction={addAction}
               investors={investors}
               showInvestor={showInvestor}
               setShowInvestor={setShowInvestor}
             />
           </Box>
         </Grid>
       )}
       {showFields && (
         <Grid item sm={3} className={classes.leftMenu}>
           <Box display="flex" justifyContent="center">
             <FieldSelect
               addAction={addAction}
               sections={sections}
               showFields={showFields}
               setShowFields={setShowFields}
               isNew={actions.length === 0}
               lastSequence={
                 actions.length > 0 ? actions[actions.length - 1].sequence : 0
               }
               resequence={resequence}
               maxSequence={
                 actions.length > 0
                   ? Math.max.apply(
                       null,
                       actions.map((action) => action.sequence)
                     )
                   : 0
               }
               allSequence={
                 actions.length > 0
                   ? actions.map((action) => action.sequence)
                   : [0]
               }
               canAddAction={canAddAction}
             />
           </Box>
         </Grid>
       )}

       {showModalEditAction && (
         <GenericModal
           title={intl.formatMessage({ id: "generic.modal.edition" })}
           component={componentEditAction()}
           click={handleFinishEditAction}
           handleClose={handleCloseEditAction}
         />
       )}
       {showModalConfirmFinish && (
         <GenericModal
           title={intl.formatMessage({ id: "generic.modal.confirm" })}
           component={componentConfirmFinish}
           click={clickConfirmFinish}
           handleClose={closeModalConfirmFinish}
         />
       )}

       <ResponsiveDialog
         message={errorMessage}
         isOpen={showErrorMessage}
         setOpen={setShowErrorMessage}
       />
     </Grid>
     <ResponsiveDialog
       message={errorMessage}
       isOpen={showErrorMessage}
       setOpen={setShowErrorMessage}
     /></>);
  };
  
  return !closed ? navigatorPage() : (
        <ResponsiveDialog
          title={"Screen Share"}
          message={"Sharing screen session has ended and this window can be closed now."}
          isOpen={true}
          hideClose={true}
        />
      );
}

Navigator.propTypes = {
  location: PropTypes.shape(PropTypes.object.isRequired),
  extractorProps: PropTypes.shape(PropTypes.object.isRequired),
};

Navigator.defaultProps = {
  location: {},
  extractorProps: { name: "", description: "" },
};

export default Navigator;
