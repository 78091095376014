import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from "prop-types";
import TextField from '@material-ui/core/TextField';
import {
  Button, Card, CardContent, Divider, Typography,
} from '@material-ui/core';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import Chip from '@material-ui/core/Chip';
import MuiAlert from '@material-ui/lab/Alert';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from "notistack";
import { FormattedMessage, useIntl } from 'react-intl';
import MessageService from '../../services/MessageService';
import LocalStorageService from "../../services/LocalStorageService";

const useStyles = makeStyles((theme) => ({
  input: {
    display: 'none',
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const defaultState = {
    replyOf: null,
    from: '',
    to: '',
    cc: '',
    bcc: '',
    subject: '',
    attachments: [],
    body: '',
    toError: '',
    subjectError: '',
    bodyError: '',
};

function MessagingNew(props) {
  const history = useHistory();
  const intl = useIntl();
  const { location } = props;
  const [MessageModal, setMessageModal] = useState(defaultState);
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  useEffect(() => {
    setTimeout(() => {
        const { params } = location;
        if (params !== undefined) {
            const { replyOf, to, cc, bcc, from, subject } = params;
            setMessageModal({ ...MessageModal, replyOf, from, to, cc, bcc, subject });
        }
    }, 100);
  }, []);

  const cancel = () => {
    history.goBack()
  }

  const handleToModal = (event) => setMessageModal((prevState) => ({ ...prevState, to: event.target.value }));

  const handleCcModal = (event) => setMessageModal((prevState) => ({ ...prevState, cc: event.target.value }));

  const handleBccModal = (event) => setMessageModal((prevState) => ({ ...prevState, bcc: event.target.value }));

  const handleSubjectModal = (event) => setMessageModal(
    (prevState) => ({ ...prevState, subject: event.target.value }),
  );

  const handleBodyModal = (event) => setMessageModal(
    (prevState) => ({ ...prevState, body: event.target.value }),
  );

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve({ name: file.name, content: reader.result });
      reader.onerror = (error) => reject(error);
    });
  }

  const reflect = (promise) => {
        return promise.then((v) => { return { v, status: "fulfilled" } },
                             (e) => { return { e, status: "rejected" } });
  }

  const handleAttachmentModal = (event) => {

   const files = [...event.target.files];
   const processFilesPromises = files.map((file) => getBase64(file));

   Promise.all(processFilesPromises.map(reflect)).then((results) => {
        const attachments = [...MessageModal.attachments];
        results.filter((r) => r.status === 'fulfilled')
               .forEach((data) => {
                    attachments.push(
                    {
                        name: data.v.name,
                        content: data.v.content.split(',').slice(1).join(',')
                    })
               });
        setMessageModal((prevState) => ({ ...prevState, attachments }));
   });
  };

  const handleDelete = (index) => {
    const attachments = [...MessageModal.attachments];
    attachments.splice(index, 1);
    setMessageModal((prevState) => ({ ...prevState, attachments }));
  };

  const clickToMessage = () => {
    let success = true;
    let newState = { ...MessageModal, toError: '', subjectError: '', bodyError: '' };
    if (MessageModal.to.trim().length === 0) {
      success = false;
      newState = { ...newState, toError: intl.formatMessage({ id: 'messaging.error.to' }) };
      setMessageModal(newState);
    }

    if (MessageModal.subject.trim().length === 0) {
      success = false;
      newState = { ...newState, subjectError: intl.formatMessage({ id: 'messaging.error.subject' }) };
      setMessageModal(newState);
    }

    if (MessageModal.body.trim().length === 0) {
      success = false;
      newState = { ...newState, bodyError: intl.formatMessage({ id: 'messaging.error.body' }) };
      setMessageModal(newState);
    }

    newState = { ...newState,
        from: MessageModal.from && MessageModal.from.trim().length > 0 ?
            MessageModal.from : LocalStorageService.getUsername(),
        cc: MessageModal.cc,
        bcc: MessageModal.bcc,
        attachments: MessageModal.attachments,
        replyOf: MessageModal.replyOf
    };

    if (success) {
      setMessageModal(newState);
      const dataset = {
        replyOf: newState.replyOf,
        from: newState.from,
        to: newState.to,
        cc: newState.cc,
        bcc: newState.bcc,
        attachments: newState.attachments,
        subject: newState.subject,
        body: newState.body
      };
      if (newState.replyOf != null) {
        MessageService.reply(dataset).then((response) => {
            const message = response.data;
            enqueueSnackbar(intl.formatMessage({ id: "messaging.send.success" }), {
                                variant: "success",
                            });
            history.go(-1);
            setTimeout(() => window.location.reload(), 100);
          }).catch(() => {
            enqueueSnackbar(intl.formatMessage({ id: "messaging.send.error" }), {
                        variant: "error",
                    });
          });
      } else {
        MessageService.sendMessage(dataset).then((response) => {
          const message = response.data;
          enqueueSnackbar(intl.formatMessage({ id: "messaging.send.success" }), {
                              variant: "success",
                          });
          history.go(-1);
          setTimeout(() => window.location.reload(), 100);
        }).catch(() => {
          enqueueSnackbar(intl.formatMessage({ id: "messaging.send.error" }), {
                      variant: "error",
                  });
        });
      }

    }
  };
  return (
    <div style={{ margin: "auto", marginTop: "10px" }}>
      <Card style={{ marginTop: '2rem' }}>
        <CardContent>
          <Typography varinat="h1" noWrap>
            <FormattedMessage id="messaging.label.new" />
          </Typography>
          <FormattedMessage id="messaging.modal.to-info">
           {(text) => (
             <Alert style={{ marginTop: "30px" }} severity="info">{text}</Alert>
           )}
          </FormattedMessage>
          <FormattedMessage id="messaging.modal.to">
            {(text) => (
              <TextField
                margin="normal"
                required
                fullWidth
                id="toModal"
                name="toModal"
                value={MessageModal.to}
                inputProps={{ maxLength: 1024 }}
                label={text}
                error={MessageModal.toError !== ''}
                helperText={MessageModal.toError}
                onChange={handleToModal}
              />
            )}
          </FormattedMessage>
          <FormattedMessage id="messaging.modal.cc">
              {(text) => (
                <TextField
                  margin="normal"
                  fullWidth
                  id="ccModal"
                  name="ccModal"
                  value={MessageModal.cc}
                  inputProps={{ maxLength: 1024 }}
                  label={text}
                  onChange={handleCcModal}
                />
              )}
            </FormattedMessage>
          <FormattedMessage id="messaging.modal.bcc">
              {(text) => (
                <TextField
                  margin="normal"
                  fullWidth
                  id="bccModal"
                  name="bccModal"
                  value={MessageModal.bcc}
                  inputProps={{ maxLength: 1024 }}
                  label={text}
                  onChange={handleBccModal}
                />
              )}
            </FormattedMessage>
          <FormattedMessage id="messaging.modal.subject">
          {(text) => (
            <TextField
              margin="normal"
              required
              fullWidth
              id="subjectModal"
              name="subjectModal"
              value={MessageModal.subject}
              inputProps={{ maxLength: 1024 }}
              label={text}
              error={MessageModal.subjectError !== ''}
              helperText={MessageModal.subjectError}
              onChange={handleSubjectModal}
            />
          )}
          </FormattedMessage>
          <FormattedMessage id="messaging.modal.attach-file">
                    {(text) => (
                    <>
                        <input
                          accept="*/*"
                          className={classes.input}
                          id="contained-button-file"
                          multiple
                          type="file"
                          onChange={handleAttachmentModal}
                        />
                        <label htmlFor="contained-button-file">
                          <Button variant="contained" color="primary" component="span">
                            <AttachFileIcon /> {text}
                          </Button>
                        </label>
                        <br /><br />
                        <>{MessageModal.attachments.map((a, index) => (
                            <Chip
                              label={a.name}
                              onDelete={() => handleDelete(index)}
                            />
                        ))}</>
                    </>

                    )}
          </FormattedMessage>
          <FormattedMessage id="messaging.modal.message">
            {(text) => (
              <TextField
                autoFocus
                multiline
                minRows={15}
                maxRows={1000}
                margin="normal"
                required
                fullWidth
                id="bodyModal"
                name="bodyModal"
                value={MessageModal.body}
                inputProps={{ maxLength: 1024 }}
                label={text}
                error={MessageModal.bodyError !== ''}
                helperText={MessageModal.bodyError}
                onChange={handleBodyModal}
              />
            )}
          </FormattedMessage>
          <Button onClick={clickToMessage} color="secondary" variant="contained">
            <FormattedMessage id="messaging.modal.send" />
          </Button>
          <Button onClick={cancel} color="secondary" variant="outlined" style={{ float: 'right' }}>
            <FormattedMessage id="generic.modal.cancel" />
          </Button>
        </CardContent>
        <Divider />
      </Card>
    </div>
  );
}

MessagingNew.propTypes = {
  location: PropTypes.shape(PropTypes.object.isRequired),
  params: PropTypes.shape(PropTypes.object.isRequired),
};

MessagingNew.defaultProps = {
  location: {},
  params: { replyOf: null, from: "", to: "" },
};

export default MessagingNew;

