import DeleteIcon from "@material-ui/icons/Delete";
import ReplyIcon from '@material-ui/icons/Reply';
import ReplyAllIcon from '@material-ui/icons/ReplyAll';
import {
  Box,
  Divider,
  Grid,
  makeStyles,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Toolbar,
  Tooltip,
  Typography,
  TextField,
  AppBar,
  Tabs,
  Tab
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import AddCircleOutlinedIcon from "@material-ui/icons/AddCircleOutlined";
import MoreVertOutlinedIcon from "@material-ui/icons/MoreVertOutlined";
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import VisibilityIcon from '@material-ui/icons/Visibility';
import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@material-ui/core/styles';
import { FormattedMessage, useIntl } from "react-intl";
import { Link, useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import GenericModal from "../shared/GenericModal";
import MessageService from "../../services/MessageService";
import UserService from "../../services/UserService";
import LocalStorageService from "../../services/LocalStorageService";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const defaultState = {
  name: "",
  description: "",
  show: false,
  nameError: "",
  descriptionError: "",
  rename: false,
};

const useStyles = makeStyles(() => ({
  title: {
    marginTop: -3,
    marginLeft: 5,
  },
  add: {
    marginRight: -7,
  },
}));

function MessagingList() {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState();
  const [MessagingModal, setMessagingModal] = useState(defaultState);
  const [CurrentMessage, setCurrentMessage] = useState();
  const [sentRows, setSentRows] = useState([]);
  const [receivedRows, setReceivedRows] = useState([]);
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const [showModalConfirmDelete, setShowModalConfirmDelete] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [value, setValue] = React.useState(0);
  const theme = useTheme();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const loadMessages = () => {
    const userId = LocalStorageService.getUserId();
    MessageService.getMessagesByUserId(userId).then(
      (response) => {
        const { data } = response;
        setSentRows(data);
      },
      () => {
        enqueueSnackbar(intl.formatMessage({ id: "messaging.list.error.get" }), {
          variant: "error",
        });
      }
    );
  };

  const loadUserDetails = () => {
    UserService.getUserDetails().then(
        (response) => {
            const { data } = response;
            LocalStorageService.setUsername(data.username);
            LocalStorageService.setUserId(data.id);
            loadMessages();
        },
        () => {
                    enqueueSnackbar(intl.formatMessage({ id: "user.not-found" }), {
                        variant: "error",
                    });
                }
    );
  };

  useEffect(() => {
    if (LocalStorageService.getUserId() == null) loadUserDetails();

    setTimeout(() => {
        loadMessages();
    }, 500);
  }, [enqueueSnackbar, intl]);

  const handleClick = (event, row) => {
    setAnchorEl(event.currentTarget);
    setCurrentMessage(row);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const showMessage = (row) => {
    history.push('/message/id/' + row.id);
  }

  const formatDatetime = (v) => new Intl.DateTimeFormat('en-US',
    {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
    }).format(v)

  const adjustSubject = (subject) => {

    if (subject == null) return "";

    if (subject.length > 30) {
      return subject.substring(0, 27) + '...';
    }

    return subject;

  }

  return (
    <>
    <Toolbar variant="dense">
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <Box display="flex" ml="-10px">
              <Tooltip title="Info about Messages">
                <MailOutlineIcon color="secondary" />
              </Tooltip>
              <Typography variant="h6" className={classes.title}>
                <FormattedMessage id="dashboard.messaging" />
              </Typography>
            </Box>
          </Grid>
          <Grid item>
            <Link to="/messaging-new" params={{ replyOf: null, from: LocalStorageService.getUsername(), to: null }}>
              <IconButton
                size="small"
                color="secondary"
                className={classes.add}
              >
                <Tooltip title="Write new message">
                  <AddCircleOutlinedIcon />
                </Tooltip>
              </IconButton>
            </Link>
          </Grid>
        </Grid>
      </Toolbar>
      <Divider />
        <AppBar position="static" color="default">
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="secondary"
            variant="fullWidth"
            aria-label="full width tabs example"
          >
            <Tab label="Sent Messages" />
          </Tabs>
        </AppBar>
        <SwipeableViews
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={value}
          onChangeIndex={handleChangeIndex}
        >
          <span>
          <TabPanel value={value} index={0} dir={theme.direction}>
            <TableContainer>
             <Table size="small">
               <TableBody>
                 {sentRows.map((row) => (
                   <TableRow key={row.name}>
                     <TableCell align="left" scope="row">
                       <Typography variant="body2">{formatDatetime(row.datetime)}</Typography>
                     </TableCell>
                     <TableCell align="left" scope="row">
                       <Typography variant="body2">{adjustSubject(row.subject)}</Typography>
                     </TableCell>
                     <TableCell align="right">
                       <VisibilityIcon
                         onClick={() => {
                           showMessage(row);
                         }}
                         style={{ cursor: "pointer" }}
                       />
                     </TableCell>
                   </TableRow>
                 ))}
               </TableBody>
             </Table>
           </TableContainer>
          </TabPanel>
          </span>
        </SwipeableViews>
    </>
  );
}

export default MessagingList;
