import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles, ThemeProvider } from "@material-ui/styles";
import { SnackbarProvider } from "notistack";
import React, { useContext, useEffect } from "react";
import { IntlProvider } from "react-intl";
import { Route, Switch, useHistory } from "react-router-dom";
import ForgotPassword from "../forgot-password/ForgotPassword";
import Home from "../Home";
import Logout from "../Logout";
import Login from "../login/Login";
import NotFound from "../NotFound";
import SignUp from "../sign-up/SignUp";
import "./App.css";
import SignMessage from "../sign-up/SignMessage";
import ConfirmRegister from "../ConfirmRegister";
import AppContext from "../../context/AppContext";
import Messaging from "../messaging/Messaging";
import MessagingNew from "../messaging/MessagingNew";

function App() {
  const history = useHistory();
  const [state] = useContext(AppContext);

  const useStyles = makeStyles(() => ({
    root: {
      flexGrow: 1,
    },
    success: { borderColor: state.theme.palette.success.light },
    error: { borderColor: state.theme.palette.error.light },
    warning: { borderColor: state.theme.palette.warning.light },
    info: { borderColor: state.theme.palette.info.light },
  }));
  const classes = useStyles();

  return (
    <ThemeProvider theme={state.theme}>
      <CssBaseline />
      <IntlProvider
        locale={state.i18nConfig.locale}
        messages={state.i18nConfig.messages}
      >
        <div id="backdrop" />
        <div id="backdropASK" />
        <SnackbarProvider
          maxSnack={3}
          anchorOrigin={{ horizontal: "center", vertical: "top" }}
          classes={{
            variantSuccess: classes.success,
            variantError: classes.error,
            variantWarning: classes.warning,
            variantInfo: classes.info,
          }}
        >
          <div className={classes.root}>
            <Switch>
              <Route path="/welcome" exact component={SignMessage} />
              <Route
                path="/user/confirmation/:token"
                exact
                component={ConfirmRegister}
              />
              <Route path="/message/id/:id" exact component={Messaging} />
              <Route path="/message/token/:token" exact component={Messaging} />
              <Route path="/messaging-new" exact component={MessagingNew} />
              <Route path="/login" exact component={Login} />
              <Route path="/logout" exact component={Logout} />
              <Route path="/signUp" exact component={SignUp} />
              <Route path="/forgotPassword" exact component={ForgotPassword} />
              <Route path="/" component={Home} />
              <Route component={NotFound} />
            </Switch>
          </div>
        </SnackbarProvider>
      </IntlProvider>
    </ThemeProvider>
  );
}

export default App;
