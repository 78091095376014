/* eslint-disable camelcase */
import { Fade, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React, { useEffect } from "react";
import { Route, useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import { FormattedMessage, useIntl } from "react-intl";
import AppToolbar from "./app-toolbar/AppToolbar";
import Dashboard from "./dashboard/Dashboard";
import Extractor from "./extractor/Extractor";
import DataSetExtractor from "./dataset/DatasetExtractor";
import SchedulerExtractor from "./scheduler/SchedulerExtractor";
import Scheduler from "./scheduler/Scheduler";
import Logout from "./Logout";
import Settings from "./settings/Settings";
import Dataset from "./dataset/Dataset";
import Query from "./query/Query";
import QueryExtractor from "./query/QueryExtractor";
import AuthenticationService from "../services/AuthenticationService";
import LocalStorageService from "../services/LocalStorageService";
import Navigator from "./navigator/Navigator";
import NavigatorViewer from "./navigator/NavigatorViewer";
import FileDownloader from "./query/FileDownloader";
import Help from "./help/Help";
import ExtractorDto from "../models/Extractor";
import ExtractorService from "../services/ExtractorService";

const useStyles = makeStyles(() => ({
  grid: {
    margin: 0,
    width: "100%",
  },
}));

function Home() {

  const { enqueueSnackbar } = useSnackbar();
  const intl = useIntl();
  const classes = useStyles();
  const history = useHistory();

  useEffect(() => {
    if (history.location.pathname.indexOf("/screen-share-fengine/") === 0) {
      const token = history.location.pathname.replace("/screen-share-fengine/", "");
      setAuthenticationToken(token, false);
    } else if (history.location.pathname.indexOf("/messaging-fengine/") === 0) {
      const token = history.location.pathname.replace("/messaging-fengine/", "");
      setAuthenticationToken(token, true);
    } else {
      if (history.location.pathname.indexOf("/screen-share-viewer/") === 0) {
        LocalStorageService.clear();
        const token = history.location.pathname.replace("/screen-share-viewer/", "");
        LocalStorageService.setNavigatorToken(token);
        LocalStorageService.setFEngine(true);
        history.push("/screen-share/" + token);
        return;
      }
      if (history.location.pathname.indexOf("/screen-share-executor/") === 0) {
        LocalStorageService.clear();
        const token = history.location.pathname.replace("/screen-share-executor/", "");
        loginToken(token);
        return;
      }
      if (LocalStorageService.getAccessToken() !== null) {
        history.push("/dashboard");
      } else {
        history.push("/login");
      }
    }
    if (history.location.pathname === "/") history.push("/dashboard");
  }, []);

  const setAuthenticationToken = (token, isMessaging) => {
      LocalStorageService.setAccessToken(token);
      LocalStorageService.setFEngine(true);
      LocalStorageService.setMessaging(isMessaging);
      sessionStorage.setItem("Auth", "true");
      //LocalStorageService.setRememberMeUser('');
      history.push("/dashboard");
  }

  const loginToken = (token) => {
      AuthenticationService.loginToken(token)
            .then((response) => {
              const { data } = response;

              //LocalStorageService.setRememberMeUser("");
              LocalStorageService.setAccessToken(data.accessToken);
              LocalStorageService.setRefreshToken(data.refreshToken);
              LocalStorageService.setUsername(data.username);
              LocalStorageService.setTempPasswordSet(data.tempPasswordSet);

              sessionStorage.setItem("Auth", "true");
              LocalStorageService.setFEngine(true);

              if (!data.enabled) {
                enqueueSnackbar(intl.formatMessage({ id: "login.user.disabled" }), {
                  variant: "error",
                });
              } else {
              const currentDate = new Date();
                                const name = "F-Engine" + currentDate.getDate() + "/"
                                    + (currentDate.getMonth() + 1) + "/"
                                    + currentDate.getFullYear() + " @ "
                                    + currentDate.getHours() + ":"
                                    + currentDate.getMinutes() + ":"
                                    + currentDate.getSeconds();

                const extractorDto = new ExtractorDto(
                    name,
                    "Screen Share",
                    data.viewerEmail,
                    "NAVIGATOR"
                );

                ExtractorService.start(extractorDto)
                .then(() => {
                  history.push({
                    pathname: "/screen-share-executor",
                    extractorProps: {
                        name,
                        description: "Screen Share",
                        viewerEmail: data.viewerEmail,
                        extractorType: "NAVIGATOR"
                    }
                  });
                })
                .catch((error) => {
                  if (error?.response?.data?.message) {
                    enqueueSnackbar(error.response.data.message,
                      { variant: "error" });
                  } else {
                    enqueueSnackbar(
                      intl.formatMessage(
                        { id: "error.unknown" },
                        { errorMessage: error?.message }
                      ),
                      { variant: "error" }
                    );
                  }
                });
              }
            })
            .catch((error) => {
              if (error?.response?.data === "Invalid token") {
                enqueueSnackbar(
                  intl.formatMessage({ id: "login.invalid_token" }),
                  { variant: "error" }
                );
              } else {
                enqueueSnackbar(
                  intl.formatMessage(
                    { id: "error.unknown" },
                    { errorMessage: error?.message }
                  ),
                  { variant: "error" }
                );
              }
              history.push("/login");
            });
  };

  return (
    <>
      {!LocalStorageService.getFEngine() ? (<AppToolbar />) : (<></>)}
      <Fade in timeout={500}>
        <Grid
          container
          direction="row"
          alignItems="stretch"
          spacing={3}
          className={classes.grid}
        >
          <Route path="/dashboard" exact component={Dashboard} />
          <Route path="/extractor" exact component={Extractor} />
          <Route path="/insertor" exact component={Extractor} />
          <Route path="/screen-share-executor" exact component={Navigator} />
          <Route path="/screen-share/:token" exact component={NavigatorViewer} />
          <Route path="/file/:token" exact component={FileDownloader} />
          <Route path="/settings" exact component={Settings} />
          <Route path="/help" exact component={Help} />
          <Route path="/dataset-extractor" exact component={DataSetExtractor} />
          <Route path="/scheduler-extractor" exact component={SchedulerExtractor} />
          <Route path="/Scheduler" exact component={Scheduler} />
          <Route path="/dataset" exact component={Dataset} />
          <Route path="/Query" exact component={Query} />
          <Route path="/query-extractor" exact component={QueryExtractor} />
          <Route path="/logout" exact component={Logout} />
        </Grid>
      </Fade>
    </>
  );
}

export default Home;
